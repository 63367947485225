<script>
import { mapActions } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      itemDiscounts: {},
      discountsArray: [],
      applicableDiscounts: [],
      freeShippingArray: [],
      allBulkDiscounts: [],
      allFreeShipping: [],
      productDiscounts: [],
      clinicDiscounts: [],
      minimumRequirement: {},
      openingOrderSuppliers: [],
      discountToGroups: [],
      discountGroups: [],
      cartSubTotal: 0,
      taxableSubTotal: 0,
      totalDiscounts: 0,
    };
  },
  async created() {
    await this.getStoreBulkDiscount();
    await this.getStoreFreeShipping();
    await this.getStoreDiscounts();
    await this.getRequirementMinimum();
    await this.fetchMinimumOrderSuppliers()
    const items = this.$store.state.eCommerce.cartItems;
    this.checkfreeShipping(items);
    this.applyBarrelDiscount(items);
  },
  computed: {
    cartItems() {
      return this.$store.state.eCommerce.cartItems.slice().reverse();
    },
    isLoggedInSuperAdmin() {
      return this.$store.state.AppActiveUser.userType === "superAdmin"
    }
  },
  methods: {
    ...mapActions("ecommerce", [
      "getMinimumOrderSuppliers",
      "getRequirementMinimumOrder",
    ]),
    ...mapActions("storeProducts", [
      "fetchStoreBulkDiscount",
      "fetchStoreFreeShipping",
      "fetchClinicDiscounts"
    ]),
    async applyBarrelDiscount(cartItems) {
        this.appliedDiscounts = [];
        this.applicableDiscounts = [];
        const clinicId = sessionStorage.getItem("doctorClinicSelectedClinic");
        let discounts = this.allBulkDiscounts;

        for (const group of this.discountGroups) {
            const associatedDiscountIds = this.discountToGroups
                .filter(d => d.groupId === group._id)
                .map(d => d.discountId);

            if (associatedDiscountIds.length > 1) {
                const associatedDiscounts = this.allBulkDiscounts.filter(d => associatedDiscountIds.includes(d._id));
                let isDoubleBarrel = true;
                for (const discount of associatedDiscounts) {
                    if (!this.isGroupDiscountApplicable(discount, cartItems, clinicId)) {
                      isDoubleBarrel = false;
                        break;
                    }
                }

                if (!isDoubleBarrel) {
                    discounts = discounts.filter(bulk =>
                        !associatedDiscounts.some(discount => discount._id === bulk._id)
                    );
                }
            } else {
                discounts = discounts.filter(bulk =>
                    !associatedDiscountIds.some(discount => discount === bulk._id)
                );
            }
        }
        this.applyDiscountsToCart(cartItems, discounts);
    },

    isGroupDiscountApplicable(discount, cartItems, clinicId) {

      const productQuantities = {};

      for (const cartItem of cartItems) {
          const productId = cartItem.productStoreId;
          const quantity = cartItem.quantity;
          if (productQuantities[productId]) {
              productQuantities[productId] += quantity;
          } else {
              productQuantities[productId] = quantity;
          }
      }

      const discountProductIdSet = new Set(discount.products.map(product => product.productId));
      let totalDiscountQuantity = 0;

      for (const productId of discountProductIdSet) {
          if (productQuantities[productId]) {
              totalDiscountQuantity += productQuantities[productId];
          }
      }

      const minTotalQTY = this.getMinTotalQuantity(discount);
      let maxTotalQTY = this.getMaxTotalQuantity(discount);

      if(!maxTotalQTY || maxTotalQTY < 1) {
        maxTotalQTY = 1000000;
      }

      let clinicIsEligible = true;
      if(discount.clinic_is_equal) {
        clinicIsEligible = discount.clinics.some(c => c.clinicId === clinicId)
      } else if(discount.clinics.length) {
        clinicIsEligible = !discount.clinics.some(c => c.clinicId === clinicId)
      }

      return (
        clinicIsEligible && (totalDiscountQuantity >= minTotalQTY && totalDiscountQuantity <= maxTotalQTY)
      );
    },
    hasClinicDiscount(productStoreId) {
      return this.clinicDiscounts.some((i) => i.productDiscounts.some((ii) => ii.productStoreId === productStoreId && ii.isActive))
    },
    async applyDiscountsToCart(cartItems, discounts) {
      let appliedDiscounts = [];
      const clinicId = sessionStorage.getItem("doctorClinicSelectedClinic");
      const productQuantities = {};  // To store total quantities of each product
      const productPrice = {};  // To store total quantities of each product
      const productListPrice = {};  // To store total quantities of each product

      // Calculate total quantities of each product in the cart
      for (const cartItem of cartItems) {
        const productId = cartItem.productStoreId;
        const quantity = cartItem.quantity;
        let price = cartItem.price;
        let listPrice = cartItem.listPrice || 0;

        // if has clinic discount, use originalPrice as price to compute total price
        if (this.hasClinicDiscount(productId) && cartItem.originalPrice) price = cartItem.originalPrice

        if (this.isLoggedInSuperAdmin && cartItem.hasOwnProperty('superAdminPrice')) price = cartItem.superAdminPrice
        if (this.isLoggedInSuperAdmin && cartItem.hasOwnProperty('superAdminDiscountPrice')) listPrice = cartItem.superAdminDiscountPrice

        if (productQuantities[productId]) {
          productQuantities[productId] += quantity;
          productPrice[productId] += price * quantity;
          productListPrice[productId] += listPrice * quantity;
        } else {
          productQuantities[productId] = quantity;
          productPrice[productId] = price * quantity;
          productListPrice[productId] = listPrice * quantity;
        }
      }

      // Apply discounts based on total product quantities
      for (const discount of discounts) {
        const discountProductIdSet = new Set(discount.products.map(product => product.productId));
        const discountClinicIdSet = new Set(discount.clinics.map(clinic => clinic.clinicId));
        let totalDiscountQuantity = 0;
        let totalPrice = 0;
        let totalListPrice = 0;
        let discountProductIds = [];

        // Calculate total quantity of products matching the discount
        for (const productId of discountProductIdSet) {
          if (productQuantities[productId]) {
            totalDiscountQuantity += productQuantities[productId];
            totalPrice += productPrice[productId];
            totalListPrice += productListPrice[productId];
            discountProductIds.push(productId);
          }
        }

        // Check if the clinic is eligible and the total quantity meets the discount rule
        let clinicIsEligible = true;
        if (discount.clinic_is_equal) {
          clinicIsEligible = discountClinicIdSet.has(clinicId);
        } else if (discount.clinics.length) {
          clinicIsEligible = !discountClinicIdSet.has(clinicId);
        }

        const minTotalQTY = this.getMinTotalQuantity(discount);
        let maxTotalQTY = this.getMaxTotalQuantity(discount);

        if (!maxTotalQTY || maxTotalQTY < 1) {
          maxTotalQTY = 1000000;
        }

        if (clinicIsEligible && (totalDiscountQuantity >= minTotalQTY && totalDiscountQuantity <= maxTotalQTY)) {
          const highestDiscountRule = this.getHighestDiscountRule(discount.discount_rules, totalDiscountQuantity, totalPrice, totalListPrice);
          if (highestDiscountRule) {
            // reset total qty to 0 to recalculate clinic discount & bulk discount
            totalDiscountQuantity = 0;
            let newProductIds = [];

            discountProductIds.map((id) => {
              const item = cartItems.find((i) => i.productStoreId === id)
              const discountPrice = this.discountedPrice(item, highestDiscountRule)

              // has clinic discount & bulk discount < to clinic discount
              if (item && this.hasClinicDiscount(id) && item.originalPrice && item.price > discountPrice) {
                newProductIds.push(id)
                totalDiscountQuantity += item.quantity
              }

              // no clinic discount
              if (!this.hasClinicDiscount(id)) {
                newProductIds.push(id)
                totalDiscountQuantity += item.quantity
              }
            })
            discountProductIds = newProductIds

            // skip if no product ids
            if (!newProductIds.length) continue;

            // Calculate the total discount based on discount value and total quantity
            let totalDiscount = highestDiscountRule.discount_value;
            if (highestDiscountRule.discount_type === 'percentage') {
              totalDiscount = this.calculateTotalDiscount(cartItems, discountProductIds, highestDiscountRule);
            } else {
              totalDiscount = totalDiscount * 100;
            }

            // Check if a similar discount is already applied
            const existingDiscountIndex = appliedDiscounts.findIndex(appliedDiscount =>
              appliedDiscount.productIds.some(productId =>
                discountProductIds.includes(productId)
              )
            );

            if (existingDiscountIndex > -1) {
              const existingDiscount = appliedDiscounts[existingDiscountIndex]
              const sameProductIds = existingDiscount.productIds.filter((id) => {
                const item = cartItems.find((i) => i.productStoreId === id)
                const prevDiscountPrice = this.discountedPrice(item, existingDiscount.rule)
                const currDiscountPrice = this.discountedPrice(item, highestDiscountRule)

                return discountProductIds.includes(id) && currDiscountPrice < prevDiscountPrice
              })

              // if same current discount products is > prev discount products
              if (sameProductIds.length) {
                let remainingTotalQty = 0;
                const remainingProductIds = existingDiscount.productIds.filter((id) => !sameProductIds.includes(id))

                // Re-calculate total qty for remaining products
                cartItems.map((i) => {
                  if (remainingProductIds.includes(i.productStoreId)) remainingTotalQty += i.quantity
                })
                // Re-calculate the total discount based on discount value and total quantity
                let updatedTotalDiscount = existingDiscount.rule.discount_value * 100;
                if (highestDiscountRule.discount_type === 'percentage') {
                  updatedTotalDiscount = this.calculateTotalDiscount(cartItems, remainingProductIds, existingDiscount.rule);
                }

                if (remainingProductIds.length) {
                  // update existing discount
                  appliedDiscounts[existingDiscountIndex] = {
                    ...existingDiscount,
                    totalDiscountQuantity: remainingTotalQty,
                    productIds: remainingProductIds,
                    totalDiscount: updatedTotalDiscount,
                  };
                } else {
                  // if no remaining product ids, delete
                  appliedDiscounts.splice(existingDiscountIndex, 1)
                }

                // push new discount
                appliedDiscounts.push({
                  discountId: discount._id,
                  totalDiscountQuantity,
                  rule: highestDiscountRule,
                  productIds: discountProductIds,
                  totalDiscount,
                  groupId: discount.groupId
                });
              }
            } else {
              // If no similar discount is applied, add the new discount
              appliedDiscounts.push({
                discountId: discount._id,
                totalDiscountQuantity,
                rule: highestDiscountRule,
                productIds: discountProductIds,
                totalDiscount,
                groupId: discount.groupId
              });
            }
          }
        }
      }

      // Add the total discount to the running total
      const totalDiscounts = appliedDiscounts.reduce((total, val)=>{
        total += val.totalDiscount;
        return total;
      }, 0);

      // Calculate the cart subtotal after applying discounts
      let subtotal = 0;
      let isTaxable = 0;
      for (const cartItem of cartItems) {
        // Use the appropriate price based on the discount rule
        const discountRule = appliedDiscounts.find(appliedDiscount =>
          appliedDiscount.productIds.includes(cartItem.productStoreId)
        );
        let price = cartItem.price;

        // has bulk discount rule & clinic discount, bulk discount < clinic discount
        if (discountRule && this.hasClinicDiscount(cartItem.productStoreId) && cartItem.originalPrice) price = cartItem.originalPrice

        if (this.isLoggedInSuperAdmin && cartItem.hasOwnProperty('superAdminPrice')) price = cartItem.superAdminPrice

        // discount is List Price, use listPrice
        if (discountRule && discountRule.rule.discount_price === "List Price") price = cartItem.listPrice

        // if key superAdminDiscountPrice exists, override discount price
        if (discountRule && this.isLoggedInSuperAdmin && cartItem.hasOwnProperty('superAdminDiscountPrice')) price = cartItem.superAdminDiscountPrice

        if(cartItem.isTaxable) {
          if (discountRule && discountRule.rule.discount_price === "List Price") {
            isTaxable += price * cartItem.quantity;
          } else if (discountRule && discountRule.rule.discount_price === "Nurse Price") {
            isTaxable += price * cartItem.quantity;
          } else if (discountRule && discountRule.rule.discount_price === "Doctor Price") {
            isTaxable += cartItem.doctorPrice * cartItem.quantity;
          } else if (discountRule && discountRule.rule.discount_price === "RRP") {
            isTaxable += cartItem.rrp * cartItem.quantity;
          } else {
            isTaxable += price * cartItem.quantity;
          }
        }

        if (discountRule && discountRule.rule.discount_price === "List Price") {
          subtotal += price * cartItem.quantity;
        } else if (discountRule && discountRule.rule.discount_price === "Nurse Price") {
          subtotal += price * cartItem.quantity;
        } else if (discountRule && discountRule.rule.discount_price === "Doctor Price") {
          subtotal += cartItem.doctorPrice * cartItem.quantity;
        } else if (discountRule && discountRule.rule.discount_price === "RRP") {
          subtotal += cartItem.rrp * cartItem.quantity;
        } else {
          subtotal += price * cartItem.quantity;
        }
      }

      // filter if there are minimum discount purchase applicable
      const filteredDiscounts = [];
      // Grouping the discounts by groupId
      const groupedDiscounts = appliedDiscounts.reduce((acc, discount) => {
        const groupId = discount.groupId;

        if (!groupId) {
          // If groupId is missing, push it right away
          filteredDiscounts.push(discount);
        } else {
          // Group by groupId
          acc[groupId] = acc[groupId] || [];
          acc[groupId].push(discount);
        }

        return acc;
      }, {});

      // Iterate over the grouped discounts and push to the filteredDiscounts array
      for (const groupId in groupedDiscounts) {
        if (groupedDiscounts[groupId].length > 1) {
          // If there are multiple discounts with the same groupId, push all of them
          filteredDiscounts.push(...groupedDiscounts[groupId]);
        }
      }

      // Include total discounts in the response
      this.applicableDiscounts = filteredDiscounts;
      this.cartSubTotal = subtotal;
      this.taxableSubTotal = isTaxable;
      this.totalDiscounts = totalDiscounts;
    },
    calculateDiscountPerCartItem(cartItem, discountRule) {
      let totalDiscount = 0;
      let price = cartItem.price;

      // if has clinic discount, use originalPrice as price to compute discount
      if (this.hasClinicDiscount(cartItem.productStoreId) && cartItem.originalPrice) price = cartItem.originalPrice

      // discount is List Price, use list Price
      if (discountRule.discount_price === "List Price") price = cartItem.listPrice

      // if superAdminDiscountPrice exists, override discount price
      if (this.isLoggedInSuperAdmin && cartItem.hasOwnProperty('superAdminDiscountPrice')) price = cartItem.superAdminDiscountPrice

      // Check if the discount_price is equal to List Price
      if (discountRule.discount_price === "List Price") {
        totalDiscount = (price * cartItem.quantity * discountRule.discount_value) / 100;
      } else if (discountRule.discount_price === "Nurse Price") {
        totalDiscount = (price * cartItem.quantity * discountRule.discount_value) / 100;
      } else if (discountRule.discount_price === "Doctor Price") {
        // Use the "Doctor Price" from your cart item
        totalDiscount = (cartItem.doctorPrice * cartItem.quantity * discountRule.discount_value) / 100;
      } else if (discountRule.discount_price === "RRP") {
        // Use the "RRP" from your cart item
        totalDiscount = (cartItem.rrp * cartItem.quantity * discountRule.discount_value) / 100;
      } else {
        totalDiscount = (price * cartItem.quantity * discountRule.discount_value) / 100;
      }

      return totalDiscount
    },
    calculateTotalDiscount(cartItems, discountProductIds, discountRule) {
      let totalDiscount = 0;
      for (const cartItem of cartItems) {
        if (discountProductIds.includes(cartItem.productStoreId)) {
          totalDiscount += this.calculateDiscountPerCartItem(cartItem, discountRule)
        }
      }
      return totalDiscount;
    },
    calculateTotalDiscountWithTax(cartItems, discountProductIds, discountRule, totalDiscountQuantity) {
      let totalDiscount = 0;
      for (const cartItem of cartItems) {
        if (discountProductIds.includes(cartItem.productStoreId) && cartItem.isTaxable) {
          // Check if the discount_price is equal to List Price
          if (discountRule.discount_price === "List Price") {
            totalDiscount += (cartItem.listPrice * cartItem.quantity * discountRule.discount_value) / 100;
          } else if (discountRule.discount_price === "Nurse Price") {
            totalDiscount += (cartItem.price * cartItem.quantity * discountRule.discount_value) / 100;
          } else if (discountRule.discount_price === "Doctor Price") {
            // Use the "Doctor Price" from your cart item
            totalDiscount += (cartItem.doctorPrice * cartItem.quantity * discountRule.discount_value) / 100;
          } else if (discountRule.discount_price === "RRP") {
            // Use the "RRP" from your cart item
            totalDiscount += (cartItem.rrp * cartItem.quantity * discountRule.discount_value) / 100;
          } else {
            totalDiscount += (cartItem.price * cartItem.quantity * discountRule.discount_value) / 100;
          }
        }
      }
      return totalDiscount;
    },
    getMinTotalQuantity(discount) {
        return Math.min(...discount.discount_rules.map(rule => rule.min_qty));
    },
    getMaxTotalQuantity(discount) {
        return Math.max(...discount.discount_rules.map(rule => rule.max_qty));
    },
    getHighestDiscountRule(discountRules, quantity, price, listPrice) {
      let highestDiscountRule = null;

      for (const discountRule of discountRules) {
        const minQTY = discountRule.min_qty;
        let maxQTY = discountRule.max_qty;
        const minAmount = (discountRule.minimum_purchase_amount ? discountRule.minimum_purchase_amount : 0) * 100;

        if(!maxQTY || maxQTY < 1) {
          maxQTY = 1000000;
        }


        if(minAmount > 0 && discountRule.discount_price === 'List Price' && minAmount <= listPrice) {
          highestDiscountRule = discountRule;
        } else if(minAmount > 0 && discountRule.discount_price === 'Nurse Price' && minAmount <= price) {
          highestDiscountRule = discountRule;
        } else if(!highestDiscountRule && minAmount > 0) {
          highestDiscountRule = null;
        } else if (!highestDiscountRule && minQTY <= quantity && maxQTY>= quantity) {
          if (!highestDiscountRule || discountRule.discount_value > highestDiscountRule.discount_value) {
            highestDiscountRule = discountRule;
          }
        }
      }

      if(highestDiscountRule) {
        return {
          ...highestDiscountRule,
          itemDiscount: highestDiscountRule.discount_type === 'fix' ? highestDiscountRule.discount_value/quantity : highestDiscountRule.discount_value
        };
      }
      return null;
    },
    checkfreeShipping(cartItems) {
      const currentClincid = sessionStorage.getItem(
        "doctorClinicSelectedClinic"
      );
      const freeShippings = _.pullAll(
        _.cloneDeep(this.allFreeShipping).map((freeShipping) => {
          const productIds = freeShipping.products.map(
            (item) => item.productId
          );
          const clinicsIds = freeShipping.clinics.map((item) => item.clinicId);
          const produuctsInCurrentLimitation = _.pullAll(
            cartItems.map((product) => {
              if (_.indexOf(productIds, product.productStoreId) >= 0) {
                return product;
              }
              return undefined;
            }),
            [undefined]
          );

          const isForcurrentClinic =
            clinicsIds.length > 0
              ? _.indexOf(clinicsIds, currentClincid) >= 0
              : true;
          if (isForcurrentClinic && produuctsInCurrentLimitation.length > 0) {
            const productQuantity = _.sum(
              produuctsInCurrentLimitation.map((product) => product.quantity)
            );
            const isBelowMax =
              !freeShipping.max_qty || freeShipping.max_qty === 0
                ? true
                : productQuantity <= freeShipping.max_qty;
            const isAboveMin = productQuantity >= freeShipping.min_qty;
            if (isBelowMax && isAboveMin) {
              if (freeShipping.discount_type === "percentage") {
                freeShipping.totalDiscount =
                  (produuctsInCurrentLimitation[0].deliveryFeeTotal *
                  freeShipping.discount_value)/100;
              } else if (
                freeShipping.discount_type === "fix" &&
                produuctsInCurrentLimitation[0].deliveryFeeTotal > 0
              ) {
                freeShipping.totalDiscount = freeShipping.discount_value * 100;
              } else {
                freeShipping.totalDiscount = 0;
              }

              return {
                ...freeShipping,
              };
            }
            return undefined;
          }
          return undefined;
        }),
        [undefined]
      );
      this.freeShippingArray = freeShippings;
    },
    async getStoreBulkDiscount() {
      const { data } = await this.fetchStoreBulkDiscount();
      this.allBulkDiscounts = data.data.discounts.docs;
      this.discountGroups = data.data.groups;
      this.discountToGroups = data.data.discountGroups;
    },
    async getStoreFreeShipping() {
      const { data } = await this.fetchStoreFreeShipping();
      this.allFreeShipping = data.data.docs;
    },
    async getStoreDiscounts() {
      try {
        const currentClincid = sessionStorage.getItem(
          "doctorClinicSelectedClinic"
        );
        const { data } = await this.fetchClinicDiscounts(currentClincid);
        this.clinicDiscounts = data.data;
      } catch (error) {
        console.log('error fetching product discount')
      }
    },
    async getRequirementMinimum() {
      try {
        const { data } = await this.getRequirementMinimumOrder();
        this.minimumRequirement = data.data;
      } catch (error) {
        console.log(`Fetching requirement order erorr: ${error}`);
      }
    },
    async fetchMinimumOrderSuppliers() {
      try {
        const { data } = await this.getMinimumOrderSuppliers();
        this.openingOrderSuppliers = JSON.parse(data.data.value)
      } catch (error) {
        console.log(`Fetching list of supplier minimum order erorr: ${error}`);
      }
    },
    discountedPrice(item, rule) {
      let price = item.price

      if (this.hasClinicDiscount(item.productStoreId) && item.originalPrice) price = item.originalPrice

      // discount is List Price, use listPrice
      if (rule.discount_price && rule.discount_price === 'List Price') price = item.listPrice

      // if key superAdminDiscountPrice exists, override discount price
      if (item.hasOwnProperty('superAdminDiscountPrice')) price = item.superAdminDiscountPrice * 100

      if (rule.discount_price && rule.discount_price === 'List Price' && rule.discount_type === 'percentage') {
        return price - (price * (rule.discount_value/100));
      } else if(rule.discount_price && rule.discount_price === 'List Price' && rule.discount_type === 'fix') {
        return price - (rule.itemDiscount * 100)
      } else if(rule.discount_type === 'percentage') {
        return price - (price * (rule.discount_value/100));
      }

      return price - (rule.itemDiscount * 100);
    },
  },
  watch: {
    "$store.state.eCommerce.cartItems": function (val) {
      // this.checkDiscounts(val);
      // this.applyDiscountsToCart(val);
      this.applyBarrelDiscount(val);
      // this.checkfreeShipping(val);
      // this.checkCartItemDiscounts(val);
    },
    applicableDiscounts: function(newval,oldval) {
      if(newval !== oldval) this.discountsArray = newval;
    }
  },
};
</script>
